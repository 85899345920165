import React, { FC } from "react";
import styles from "./Home.module.scss";

const Home: FC = () => {
  return (
    <div className={styles["Home"]}>
      <div className={styles["Content"]}>
        <div className={styles["Block"]}>
          <span className={styles["Title"]}>Schenato Tecnology & Software</span>
          <span className={styles["SubTitle"]}>Desenvolvedor Full Stack</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
