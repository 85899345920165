import React, { FC, useState } from "react";
import { SectionTab } from "../../Components/Shell";
import Home from "../Home";

import styles from "./ShellHost.module.scss";

const ShellHost: FC = () => {
  const [dark] = useState<boolean>(true);

  return (
    <div className={styles["ShellHost"]}>
      {/* <TopBar /> */}
      <SectionTab dark={dark} identifier="home">
        <Home />
      </SectionTab>
      {/* <SectionTab dark={dark} identifier="sobre">
        <Sobre />
      </SectionTab> */}
    </div>
  );
};

export default ShellHost;
