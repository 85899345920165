import React, { FC } from "react";
import styles from "./Section.module.scss";

interface ISection {
  dark: boolean;
  identifier: string;
}

export const SectionTab: FC<ISection> = ({ dark, children, identifier }) => {
  return (
    <div
      className={`${styles["Section"]} ${dark ? styles["-dark"] : undefined}`}
    >
      <div className={styles["section-content"]} id={identifier}>
        {children}
      </div>
    </div>
  );
};
